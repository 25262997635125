/*
 * Content:
 *
 * Base
 * Table cell action
 */


// Base
// ============================================================================

@use "sass:math";

.table {
  // Set height and min-height to allow setting height in child elements of a td
  // https://stackoverflow.com/questions/3215553/make-a-div-fill-an-entire-table-cell
  height: 100%;
  min-height: 1px;

  & > :not(:first-child) {
    border-top: none;
  }

  thead {
    tr {
      th {
        padding: 0;
        vertical-align: baseline;
        background-color: $table-th-bg;

        &:first-child {
          border-top-left-radius: $border-radius;
        }

        &:last-child {
          border-top-right-radius: $border-radius;
        }

        &.table-cell-action {
          .th-head {
            padding: 0;
          }
        }

        .th-head {
          padding: $table-cell-padding-y $table-cell-padding-x;
          padding-right: $table-cell-padding-x + $table-head-sort-icon-font-size + $table-head-sort-icon-margin;
          color: $body-color;
          text-decoration: none;

          &:not(.hide-icon) {
            background-image: escape-svg($table-head-sort-icon);
            background-repeat: no-repeat;
            background-position: right $table-cell-padding-x top ($table-cell-padding-y + math.div(($table-head-font-size * $table-head-line-height) - $table-head-sort-icon-font-size, 2));
            background-size: $table-head-sort-icon-font-size $table-head-sort-icon-font-size;
          }

          &.table-head-sort-asc {
            background-image: escape-svg($table-head-sort-icon-asc);
          }

          &.table-head-sort-desc {
            background-image: escape-svg($table-head-sort-icon-desc);
          }
        }

        .form-check-input {
          &:not(:checked) {
            background-color: $table-th-bg;
            border-color: $body-color;
          }
        }
      }
    }
  }

  td {
    word-break: break-word;
    word-wrap: break-word;
  }
}


// Table cell action
// ============================================================================

.table-cell-action {
  width: 2.5rem; // 40px
  // Use important to change bootstrap table cell padding without having to use a lot of selectors
  padding: 0 spacer(2) !important; // stylelint-disable-line declaration-no-important
  text-align: center;
  vertical-align: middle;

  &:last-child {
    position: sticky;
    right: 0;


    &.cell-active {
      &:not(th) {
        // Use important to override bootstrap shadow assignment
        /* stylelint-disable-next-line declaration-no-important */
        box-shadow: inset 0 0 3px rgb(0 0 0 / 30%) !important;
      }

      .table-option {
        height: 100%;

        & > * {
          height: 100%;
        }
      }
    }
  }

  .icon {
    color: $primary;

    [class^="material-icons"] {
      padding: spacer(1);
      border-radius: 999px;

      &:hover {
        background-color: $red-wine-disabled;
      }
    }
  }
}
