/*
 * Content:
 *
 * Base
 * Tooltip inner
 * Tooltip positions
 * Tooltip secondary
 */


// Base
// ============================================================================

.tooltip {
  .tooltip-arrow {
    display: none;
  }
}


// Tooltip inner
// ============================================================================

.tooltip-inner {
  box-shadow: $card-box-shadow;
}


// Tooltip positions
// ============================================================================

.bs-tooltip-start {
  .tooltip-inner {
    border-bottom-right-radius: 0;
  }
}


// Tooltip secondary
// ============================================================================

.tooltip-secondary {
  &.show {
    opacity: 1;
  }

  .tooltip-inner {
    background-color: $red-wine-disabled;
    border-radius: $tooltip-border-radius;
    opacity: 1;
  }
}
