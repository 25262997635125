/*
 * Content:
 *
 * Base
 * Header
 * Title
 * Sidebar offcanvas
 * Offcanvas navbar
 */


// Base
// ============================================================================

.offcanvas {
  align-items: center;

  .nav {
    width: 100%;
    padding: spacer(2);
    margin-top: spacer(2);
    background-color: $card-bg;
    border-radius: $card-border-radius;
    box-shadow: $card-box-shadow;
  }
}


// Header
// ============================================================================

.offcanvas-header {
  padding-bottom: spacer(5);
}

// Title
// ============================================================================

.offcanvas-title {
  text-transform: capitalize;
}


// Sidebar offcanvas
// ============================================================================

.sidebar-offcanvas {
  margin-top: $navbar-min-height;
}


// Offcanvas navbar
// ============================================================================

.offcanvas-navbar {
  .nav {
    margin-top: calc(#{$navbar-min-height} + #{spacer(2)});
  }
}
