/*
 * Content:
 *
 * Form control
 * Form control for file input
 * Form control date input
 */


// Form control
// ============================================================================

.form-control {
  &:disabled,
  &:disabled::placeholder {
    color: $input-disabled-color;
  }
}


// Form control for file input
// ============================================================================

input[type="file"].form-control {
  position: relative;

  &::file-selector-button,
  &::-webkit-file-upload-button {
    position: absolute;
    right: 0;
    height: 100%;
    margin-right: 0;
    background-color: $form-file-button-bg;
    border-right: none;
    border-left: $input-border-width * 2 solid $input-border-color;
  }
}


// Form control date input
// ============================================================================

input[type="date"].form-control {
  position: relative;

  &::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10; // To make the ::after  element clickable
    width: 100%;
    height: 100%;
    padding: 0;
    color: transparent;
    cursor: pointer;
    background: transparent;
  }

  &::after {
    position: absolute;
    right: $input-padding-x;
    font-family: "Material Icons", serif;
    color: $input-color;
    content: "calendar_today";
  }
}
