/*
 * Content:
 *
 * Base
 * Sidebar item
 * Sidebar item collapse
 */

// Base
// ============================================================================

.sidebar {
  .nav-pills {
    .nav-link {
      border-radius: .5rem; // 8px
    }
  }
}


// Sidebar item
// ============================================================================

.sidebar-item > .nav-link {
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  color: $gray-900;
  transition: none;
}


// Sidebar item collapse
// ============================================================================

.sidebar-item-collapse {
  position: relative;
  border-radius: .5rem; // 8px

  &.active {
    background-color: $nav-pills-link-active-bg;
  }


  .nav-link {
    padding-right: $dropdown-item-padding-x * 2;

    &::after {
      @include caret-up();
    }

    &.collapsed {
      &::after {
        @include caret-down();
      }
    }
  }
}
