/*
 * Content:
 *
 * Modules
 * Base
 * Dropdown item
 * Dropdown item label
 * Dopdown without caret
 * Dropdown actions
 * Dropdown link actions
 * Dropdown header
 */


// Modules
// ============================================================================

@use "sass:map";


// Base
// ============================================================================

.dropdown-menu {
  box-shadow: $dropdown-box-shadow;
}


// Dropdown item
// ============================================================================

.dropdown-item {
  display: flex;
  align-items: center;
  border-radius: $border-radius;
  gap: spacer(2);

  &.active {
    font-weight: 700;
  }
}


// Dropdown item label
// ============================================================================

.dropdown-item-label {
  flex: 1;
}


// Dropdown without caret
// ============================================================================

.dropdown-no-caret {
  .dropdown-toggle {
    &::after {
      display: none;
    }
  }
}


// Dropdown actions
// ============================================================================

.dropdown-actions {
  .dropdown-item {
    font-weight: 700;
    color: $primary;
    background-color: $primary-disabled;
    border-radius: 0;
  }

  .dropdown-menu {
    background-color: $primary-disabled;

    /*
      To make the dropdown menu have the same width as the .container
      according to the breakpoint available
    */
    @each $breakpoint,
      $value in $container-max-widths {
      @include media-breakpoint-up(#{$breakpoint}) {
        max-width: $value;

        @if $breakpoint == "md" {
          width: calc($value - $main-content-md-padding-x * 2);
        }

        @else {
          width: calc($value - $main-content-padding-x * 2);
        }
      }
    }
  }

  li {
    &:first-child {
      .dropdown-item {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
      }
    }

    &:last-child {
      .dropdown-item {
        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }
    }
  }
}


// Dropdown link actions
// ============================================================================

.dropdown-link-actions {
  .dropdown-menu {
    width: calc(100vw - $main-content-padding-x * 2 - $card-spacer-x * 2);
    min-width: unset;

    @each $breakpoint,
      $value in $container-max-widths {
      @include media-breakpoint-up(#{$breakpoint}) {
        width: calc($value - $main-content-padding-x * 2 - $card-spacer-x * 2);

        @if $breakpoint == "md" {
          width: calc($value - $main-content-md-padding-x * 2 - $card-desktop-spacer-x * 2);
          min-width: $dropdown-min-width;
        }

        @if $breakpoint == "lg" {
          max-width: 15.9375rem; // 255px approx.
        }
      }
    }
  }

  & > .dropdown-link-actions-trigger {
    .icon {
      color: $gray-600;
    }

    &.show {
      color: $primary;
      background-color: $primary-disabled;

      .icon {
        color: inherit;
      }
    }
  }
}


// Dropdown header
// ============================================================================

.dropdown-header {
  font-size: $font-size-base; // @include font-size($font-size-sm);
  font-weight: $font-weight-bold;
}
