/*
 * Content:
 *
 * Modules
 * Alert
 * Highlight alert
 * Alert dismissible
 * Color variants
 * Global alert
 */


// Modules
// ============================================================================

@use "sass:color";


// Alert
// ============================================================================

.alert {
  display: flex;
  align-items: center;
  box-shadow: $card-box-shadow;

  &::before {
    margin-right: spacer(1);
    font-family: "Material Icons", serif;
    font-size: $font-size-base * 1.25; // 20px
  }
}


// Highlight alert
// ============================================================================

.highlight {
  @each $color,
    $value in $theme-colors {
    &.alert-#{$color} {
      background: color.adjust($value, $lightness: -10%);
    }
  }
}


// Alert dismissible
// ============================================================================

.alert-dismissible {
  .alert-text {
    flex: 1;
  }

  .btn-close {
    position: relative;
    padding: 0;
    margin-left: spacer(2);
  }
}


// Color variants
// Our alerts have different styles than bootstrap, so we overwrite the alert
// generator. NOTE: This makes some bootstrap alert variables unavailable
// ============================================================================

@each $state,
  $value in $theme-colors {
  $alert-background: $white;
  $alert-border: $value;
  $alert-color: $gray-600;
  $icon-type: $state;
  $icon-color: $value;

  .alert-#{$state} {
    @if $state == "secondary" {
      $alert-color: $gray-dark;
      $alert-border: $white-dark;
      $icon-color: $gray-dark;
    }

    @else if $state == "success" {
      $icon-color: $green-dark;
    }

    @else if $state == "danger" {
      $alert-border: $red-light;
      $icon-color: $red-dark;
    }

    @else if $state == "info" {
      $alert-border: $cyan-light;
      $icon-color: $cyan-dark;
    }

    @else if $state == "light" {
      $alert-border: $white-medium;
      $icon-color: $gray-600;
    }

    @else if $state == "dark" {
      $alert-border: $gray-medium;
      $icon-color: $gray-darker;
    }

    @include our-alert-variant($alert-background, $alert-border, $alert-color, $icon-type, $icon-color);
  }
}


// Global alert
// ============================================================================

.global-alert {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 9999; // To force it to be above all bootstrap components
}
