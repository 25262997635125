/*
 * Content:
 *
 * Pagination icons
 * Pagination item
 */


// Pagination icons
// ============================================================================

.pagination {
  .icon {
    font-size: $font-size-base;
    color: inherit;
  }
}


// Pagination item
// ============================================================================

.page-item {
  margin-right: $pagination-item-gap;

  &:last-child {
    margin-right: 0;
  }
}
