/*
 * Content:
 *
 * Headings
 */

// Headings
// ============================================================================

h1 {
  font-weight: $h1-font-weight;
}

h2 {
  font-weight: $h2-font-weight;
}

h3 {
  font-weight: $h3-font-weight;
}

h6 {
  font-weight: $h6-font-weight;
}
