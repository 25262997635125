/*
 * Content:
 *
 * Collapsing transition with choices.js
 * Accordion item
 * Accordion header
 * Accordion body
 * Accordion button
 */


// Collapsing transition with choices.js
// When a collapsable element has child that is a select there is a jumpy effect while collapsing.
// This fixes that behavior.
// ============================================================================

.collapsing {

  /* stylelint-disable-next-line selector-class-pattern */
  .choices__list.choices__list--dropdown {
    display: none;
  }
}


// Accordion item
// ============================================================================

.accordion-item {
  box-shadow: $accordion-shadow;

  @include border-radius($accordion-border-radius);

  .accordion-button {
    @include border-radius($accordion-border-radius);

    &:not(.collapsed) {
      @include border-bottom-radius(0);
    }
  }

  &:not(:last-child) {
    margin-bottom: spacer(6);
  }
}


// Accordion header
// ============================================================================

.accordion-header {
  .accordion-button {
    font-size: inherit;
    font-weight: inherit;
  }
}


// Accordion body
// ============================================================================

.accordion-body {
  dl {
    dd {
      word-break: break-word;
    }
  }

  @include media-breakpoint-up(md) {
    padding: $accordion-body-md-padding-y $accordion-body-md-padding-x;
  }
}


// Accordion button
// ============================================================================

.accordion-button {
  &::after {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: spacer(1);
    font-family: "Material Icons", sans-serif;
    content: "keyboard_arrow_down";
  }

  @include media-breakpoint-up(md) {
    padding: $accordion-button-md-padding-y $accordion-button-md-padding-x;
  }
}
