/*
 * Content:
 *
 * Breadcrumb item
 * Breadcrumb secondary
 */


// Breadcrumb item
// ============================================================================

.breadcrumb-item {
  a {
    text-decoration: none;
  }
}


// Breadcrumb secondary
// ============================================================================

.breadcrumb-secondary {
  margin-bottom: spacer(3);

  .breadcrumb-item {
    padding: spacer(1);

    & + .breadcrumb-item {
      &::before {
        margin-right: spacer(1);
        font-family: "Material icons", serif;
        content: "keyboard_arrow_right";
      }
    }

    a {
      color: inherit;
    }
  }
}
