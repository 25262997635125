/*
 * Content:
 *
 * Modules
 * Paths
 * Color system
 * Spacers
 * Body
 * Utilities maps
 * Grid
 * Components
 * Typography
 * Layout
 * Buttons
 * Navbar
 * Forms
 * Shadows
 * Dropdown
 * Close
 * Bootstrap variables
 * Choices variables
 * Tables
 * Cards
 * Badges
 * Alerts
 * List group
 * Breadcrumbs
 * Pagination
 * Tooltips
 */


// Modules
// ============================================================================

@use "sass:map";
@use "sass:color";


// Paths
// ============================================================================

$images-path: "../img/";
$fonts-path: "../fonts/";


// Color system
// ============================================================================

// Grays
$white-medium: #f7f7f7;
$white: #fff;
$white-dark: #d3d6d8;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$gray-light: #9d9d9c;
$gray-medium: #bcbebf;
$gray-dark: #41464b;
$gray-600-hover: #777776;
$gray-600-focus: #90908f;
$gray-darker: #141619;
$black: #000;

$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900,
  "dark": $gray-dark
);

$grays-rgb: map-loop($grays, to-rgb, "$value");

// Colors
$blue: #0d6efd;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red-100: #f8d7da;
$red-light: #f5c2c7;
$red: #dc3545;
$red-dark: #842029;
$orange: #fd7e14;
$yellow-100: #fff3cd;
$yellow: #ffc107;
$yellow-700: #997404;
$green-100: #d1e7dd;
$green: #198754;
$green-dark: #0f5132;
$teal: #20c997;
$cyan-light: #b6effb;
$cyan: #0dcaf0;
$cyan-dark: #055160;
$red-wine: #880d27;
$red-wine-disabled: #ece0e2;
$red-wine-dark: #420613;


$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red-light": $red-light,
  "red": $red,
  "red-dark": $red-dark,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "green-dark": $green-dark,
  "teal": $teal,
  "cyan-light": $cyan-light,
  "cyan": $cyan,
  "cyan-dark": $cyan-dark,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800
);

// Themes
$primary: $red-wine;
$primary-disabled: $red-wine-disabled;
$secondary: $gray-light;
$secondary-disabled: color.adjust($secondary, $lightness: 20%);
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $white-medium;
$dark: $gray-900;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "secondary-disabled": $secondary-disabled,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark
);

$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");


// Spacers
// ============================================================================

$spacer: 1rem;
$spacers: (
  0: 0,
  // 4px
  1: $spacer * .25,
  // 8px
  2: $spacer * .5,
  // 16px
  3: $spacer,
  // 20px
  4: $spacer * 1.25,
  // 24px
  5: $spacer * 1.5,
  // 32px
  6: $spacer * 2,
  // 48px
  7: $spacer * 3,
);


// Body
// ============================================================================

$body-color: $gray-900;
$body-bg: $white-medium;


// Utilities maps
// ============================================================================

$utilities-colors: map.merge(
  map.merge(
    $theme-colors-rgb,
    map-add-key-prefix($grays-rgb, gray-)
  ),
  ("black": to-rgb($black),
  "white": to-rgb($white),
  "body": to-rgb($body-bg))
);


// Grid
// ============================================================================

$grid-gutter-width: 2rem;


// Components
// ============================================================================

$shadow-color: rgb(0 0 0 / 25%);
$border-radius: .5rem; // 8px
$caret-spacing: spacer(2);


// Typography
// ============================================================================

$font-size-base-in-px: 16px;
$font-weight-normal: 400;

// font-size bootstrap reference for use font-size bootstrap mixin (responsive font size)
// see bootstrap/scss/_type.scss
// see bootstrap/scss/vendor/_rfs.scss
$font-size-base: 1rem;
$font-size-lg: $font-size-base * 1.25;
$font-size-sm: $font-size-base * .875;

$line-height-base: 1.5;

// Headings
$h1-font-size: $font-size-base * 2.5;
$h1-font-weight: 700;
$h2-font-size: $font-size-base * 2;
$h2-font-weight: 600;
$h3-font-size: $font-size-base * 1.75;
$h3-font-weight: 400;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base * 1.125;
$h6-font-weight: 500;

$headings-line-height: 1.2;

// Displays
$display1-size: 6rem;
$display2-size: 5.5rem;
$display3-size: 4.5rem;
$display4-size: 3.5rem;

$display-line-height: $headings-line-height;

// Lead paragraph
$lead-font-size: $font-size-base * 1.25;

// Small text
$small-font-size: 80%;

// Font Family
$font-family-sans-serif: "Roboto", sans-serif;
$font-family-base: $font-family-sans-serif;

// Weights
$font-weight-bold: 700;
$font-weight-semibold: 600;
$font-weight-medium: 500;
$font-weight-regular: $font-weight-normal;


// Layout
// ============================================================================

$main-content-padding-y: spacer(6);
$main-content-padding-x: spacer(3);
$main-content-md-padding-x: $main-content-padding-y;


// Buttons
// ============================================================================

$btn-padding-y: spacer(2);
$btn-padding-x: spacer(3);
$btn-color: $white;
$btn-border-radius: 9999px; // To set border radius as 50% of btn height

// btn sm
$btn-padding-y-sm: spacer(1);
$btn-padding-x-sm: spacer(2);
$btn-border-radius-sm: $btn-border-radius;
$btn-font-size-sm: $font-size-base;

// btn lg
$btn-padding-y-lg: spacer(2);
$btn-padding-x-lg: spacer(3);
$btn-border-radius-lg: $btn-border-radius;
$btn-font-size-lg: $font-size-lg;

//btn icon
$btn-icon-border-radius: 50%;
$btn-icon-padding: spacer(2);
$btn-icon-font-size: 1.5rem; // 24px

//btn icon sm
$btn-icon-sm-padding: spacer(1);
$btn-icon-sm-font-size: $btn-icon-font-size;

// btn icon lg
$btn-icon-lg-padding: $btn-icon-padding;
$btn-icon-lg-font-size: 1.875rem; // 30px

// btn link
$btn-link-disabled-color: $white;

/* Approximate percentage values to generate button variants */
$btn-active-bg-shade-amount: 10%;
$btn-hover-bg-shade-amount: 51%;
$btn-hover-border-shade-amount: 51%;
$btn-disabled-bg-tint-amount: 55%;
$btn-disabled-border-tint-amount: 55%;
$btn-outline-hover-border-shade-amount: 30%;


// Navbar
// ============================================================================

$navbar-min-height: 74px;
$navbar-nav-item-link-padding-x: 14.4px;

// Nav link
$nav-link-padding-y: spacer(1);
$nav-link-padding-x: spacer(3);
$nav-link-color: $body-color;
$navbar-nav-link-padding-x: spacer(3);

// Nav pills
$nav-pills-link-active-font-weight: 700;
$nav-pills-link-active-color: $primary;
$nav-pills-link-active-bg: $primary-disabled;
$nav-pills-border-radius: 1rem;

// Nav toggler
$navbar-toggler-padding-y: spacer(2);
$navbar-toggler-padding-x: $navbar-toggler-padding-y;


// Forms
// ============================================================================

$input-bg: $white;
$input-color: $body-color;
$input-padding-y: spacer(1);
$input-padding-x: spacer(2);
$input-border-width: 1px;
$input-border-color: $gray-400;
$input-border-radius: 4px;

$input-focus-border-color: tint-color($primary, 50%);

$input-placeholder-color: $gray-600;
$input-disabled-color: $input-placeholder-color;

// Label
$form-label-margin-bottom: spacer(1);

// Select
$form-select-disabled-color: $input-disabled-color; // This value can't be `null` for overriding choices styles

// Legend
$legend-font-size: $font-size-base;

// Check
$form-check-input-border: 1px solid $body-color;
$form-check-input-border-radius: 2px;


// Shadows
// ============================================================================

$box-shadow: 0 2px 4px $shadow-color;
$box-shadow-md: 0 .13rem .26rem $shadow-color;


// Dropdown
// ============================================================================

$dropdown-padding-x: spacer(2);
$dropdown-padding-y: $dropdown-padding-x;
$dropdown-box-shadow: $box-shadow;
$dropdown-border-width: 0;
$dropdown-min-width: 20.625rem; // 314px
$dropdown-spacer: spacer(2);

//dropdown link
$dropdown-link-hover-color: $primary;
$dropdown-link-hover-bg: rgba($primary, $alpha: .1);
$dropdown-link-active-bg: $dropdown-link-hover-bg;
$dropdown-link-active-color: $dropdown-link-hover-color;

//dropdown item
$dropdown-item-padding-y: spacer(3);
$dropdown-item-padding-x: spacer(2);

//dropdown header
$dropdown-header-color: $body-color;
$dropdown-header-padding-y: $dropdown-item-padding-y;
$dropdown-header-padding-x: $dropdown-item-padding-x;
$dropdown-header-padding: $dropdown-header-padding-y $dropdown-header-padding-x; // Deprecated in v5.2.0, so we have to set it for now

//dropdown divider
$dropdown-divider-margin-y: spacer(1);


// Close
// ============================================================================

$btn-close-width: $spacer * .875; // 14px
$btn-close-height: $btn-close-width;

$btn-close-color: $gray-600;

$btn-close-bg: url("data:image/svg+xml,<svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z' fill='#{$btn-close-color}'/></svg>");


// Offcanvas
// ============================================================================

$offcanvas-border-width: 0;
$offcanvas-padding-y: spacer(6);
$offcanvas-horizontal-width: 100%;
$offcanvas-sm-horizontal-width: 15.3125rem; // Approx 245px
$offcanvas-bg-color: transparent;


// Bootstrap variables
// ============================================================================

@import "~bootstrap/scss/variables";
$enable-negative-margins: true;


// Choices variables
// ============================================================================

$choices-guttering: 0;
$choices-border-radius: $form-select-border-radius;
$choices-bg-color: $form-select-bg;
$choices-bg-color-disabled: $form-select-disabled-bg;
$choices-keyline-color: $form-select-border-color;

// Dropdown
$choices-bg-color-dropdown: $gray-100;
$choices-dropdown-border-width: 0;
$choices-dropdown-border-color: $choices-keyline-color;

$choices-dropdown-item-paddiny-y: $form-select-padding-y;
$choices-dropdown-item-paddiny-x: $form-select-padding-x;
$choices-dropdown-item-font-size: $font-size-base;
$choices-dropdown-item-line-height: $line-height-base;
$choices-dropdown-item-color: $dropdown-link-color;

$choices-dropdown-item-active-color: $dropdown-link-active-color;
$choices-dropdown-item-active-bg: $dropdown-link-active-bg;

$choices-dropdown-item-hover-color: $dropdown-link-hover-color;
$choices-dropdown-item-hover-bg: $dropdown-link-hover-bg;

$choices-dropdown-item-disabled-color: $dropdown-link-disabled-color;

$choices-dropdown-group-item-padding-l: $choices-dropdown-item-paddiny-x * 2;

// Multiple item
$choices-border-radius-item: $badge-border-radius;
$choices-multiple-item-padding-y: 0;
$choices-multiple-item-padding-x: spacer(1);
$choices-multiple-item-bg: $gray-100;
$choices-multiple-item-font-size: $font-size-base;
$choices-multiple-item-line-height: $line-height-base;
$choices-multiple-item-color: $secondary;
$choices-multiple-item-border-width: $border-width;
$choices-multiple-item-border-color: $choices-multiple-item-color;
$choices-multiple-item-spacer: spacer(2);

$choices-multiple-item-disabled-bg: $form-select-disabled-bg;
$choices-multiple-item-disabled-color: $choices-multiple-item-color;
$choices-multiple-item-disabled-border-color: $choices-multiple-item-border-color;

// Remove button
$choices-button-dimension: .5rem;
$choices-button-offset: spacer(1);
$choices-button-color: $choices-multiple-item-color;
$choices-button-disabled-color: $choices-multiple-item-disabled-color;
$choices-button-focus-shadow: $btn-close-focus-shadow;

$choices-icon-cross-svg: str-replace($btn-close-bg, #{$btn-close-color}, #{$choices-button-color});
$choices-icon-cross: escape-svg($choices-icon-cross-svg);

// Heading
$choices-heading-padding-y: $choices-dropdown-item-paddiny-y;
$choices-heading-padding-x: $choices-dropdown-item-paddiny-x;
$choices-heading-font-weight: $font-weight-base;
$choices-heading-font-size: $font-size-base;
$choices-heading-line-height: $line-height-base;
$choices-heading-color: $gray-700;
$choices-heading-border-width: $border-width;
$choices-heading-border-color: $border-color;

// Tables
// ============================================================================

$table-bg: $white;

$table-head-font-size: $font-size-base;
$table-head-line-height: 1.5;
$table-th-font-weight: $font-weight-bold;

$table-head-sort-icon: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M12.0002 5.83L15.1702 9L16.5802 7.59L12.0002 3L7.41016 7.59L8.83016 9L12.0002 5.83ZM12.0002 18.17L8.83016 15L7.42016 16.41L12.0002 21L16.5902 16.41L15.1702 15L12.0002 18.17Z' fill='#{$body-color}'/></svg>");
$table-head-sort-icon-asc: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M12.0002 5.83L15.1702 9L16.5802 7.59L12.0002 3L7.41016 7.59L8.83016 9L12.0002 5.83Z' fill='#{$body-color}'/></svg>");
$table-head-sort-icon-desc: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M11.9999 18.17L8.82992 15L7.41992 16.41L11.9999 21L16.5899 16.41L15.1699 15L11.9999 18.17Z' fill='#{$body-color}'/></svg>");
$table-head-sort-icon-font-size: $table-head-font-size;
$table-head-sort-icon-margin: spacer(1);

$table-cell-padding-y: spacer(2);
$table-cell-padding-x: spacer(3);

// Filters table
$filters-table-box-shadow: inset 0 -2px 4px rgb(0 0 0 / 15%), inset 0 2px 4px rgb(0 0 0 / 15%);

// Colors table
$table-th-bg: $light;
$table-border-color: $secondary-disabled;


// Cards
// ============================================================================

$card-border-width: 0;
$card-box-shadow: $box-shadow;
$card-bg: $white;

// Body
$card-spacer-y: spacer(3);
$card-spacer-x: spacer(3);
$card-desktop-padding-top: $card-spacer-y;
$card-desktop-spacer-y: spacer(5);
$card-desktop-spacer-x: spacer(5);

// Header
$card-inner-border-radius: $card-border-radius;
$card-cap-padding-y: $card-spacer-y;
$card-cap-padding-x: $card-spacer-x;
$card-cap-desktop-padding-top: $card-desktop-spacer-y;
$card-cap-desktop-padding-x: $card-desktop-spacer-x;
$card-cap-bg: $white;

// Title
$card-title-spacer-y: 0;


// Badges
// ============================================================================

$badge-padding-y: spacer(1);
$badge-padding-x: spacer(3);
$badge-font-size: .875rem; // 14px
$badge-color: $white;


// Accordion
// ============================================================================

$accordion-bg: $white;
$accordion-shadow: $card-box-shadow;
$accordion-border-color: $gray-300;
$accordion-border-radius: $border-radius;
$accordion-padding-y: spacer(3);
$accordion-padding-x: $accordion-padding-y;
$accordion-md-padding-y: spacer(5);
$accordion-md-padding-x: $accordion-md-padding-y;

// Button
$accordion-button-bg: $accordion-bg;
$accordion-button-active-bg: $accordion-button-bg;
$accordion-button-color: $body-color;
$accordion-button-active-color: $accordion-button-color;
$accordion-button-focus-box-shadow: 0 0 0 3px rgba($blue, .25);
$accordion-button-padding-y: $accordion-padding-y;
$accordion-button-padding-x: $accordion-button-padding-y;
$accordion-button-md-padding-y: $accordion-md-padding-y;
$accordion-button-md-padding-x: $accordion-md-padding-x;

// Icon
$accordion-button-icon: none;
$accordion-button-active-icon: none;
$accordion-icon-width: 1.5rem; // 24px

// Body
$accordion-body-padding-y: $accordion-padding-y;
$accordion-body-padding-x: $accordion-padding-x;
$accordion-body-md-padding-y: $accordion-md-padding-y;
$accordion-body-md-padding-x: $accordion-md-padding-x;


// Alerts
// ============================================================================

$alert-bg-scale: 50%;
$alert-padding-x: $spacer;

$alert-icons: (
  "info": "info",
  "primary": "info",
  "secondary": "info",
  "success": "check_circle",
  "danger": "warning",
  "warning": "warning",
  "light": "info",
  "dark": "info",
);

// Alert dismissible
$alert-dismissible-padding-r: $alert-padding-x;


// List group
// ============================================================================

$list-group-item-padding-x: spacer(2);
$list-group-border-color: $gray-300;
$breadcrumb-margin-bottom: spacer(5);


// Breadcrumbs
// ============================================================================

$breadcrumb-margin-bottom: spacer(5);
$breadcrumb-item-padding-x: spacer(1);
$breadcrumb-divider-color: $primary;


// Pagination
// ============================================================================

$pagination-border-width: 0;
$pagination-padding-y: 0;
$pagination-padding-x: 0;
$pagination-color: $body-color;
$pagination-hover-bg: none;
$pagination-hover-color: unset;
$pagination-focus-bg: none;
$pagination-focus-color: unset;
$pagination-item-gap: spacer(1);


// Tooltips
// ============================================================================

$tooltip-bg: $white;
$tooltip-arrow-color: $tooltip-bg;
$tooltip-color: $body-color;
$tooltip-border-radius: 4px;
