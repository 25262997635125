/*
 * Content:
 *
 * Base
 * Button icon
 * Button icon sm
 * Button icon lg
 * Button group
 * Button variants
 * Button borderless
 * Button link
 * Button link variants
 */


// Base
// ============================================================================

.btn {
  .icon {
    font-size: 1.5rem; // 24px
    color: inherit;
  }
}


// Button icon
// ============================================================================

.btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $btn-icon-padding;
  border-radius: $btn-icon-border-radius;

  .icon {
    font-size: $btn-icon-font-size;
    color: inherit;
  }

  &:hover {
    .icon {
      color: inherit;
    }
  }
}


// Button icon sm
// ============================================================================

.btn-icon-sm {
  padding: $btn-icon-sm-padding;

  .icon {
    font-size: $btn-icon-sm-font-size;
  }
}


// Button icon lg
// ============================================================================

.btn-icon-lg {
  padding: $btn-icon-lg-padding;

  .icon {
    font-size: $btn-icon-lg-font-size;
  }
}


// Button group
// ============================================================================

.btn-group {
  & > .btn-icon {
    &:not(:last-child):not(.dropdown-toggle) {
      border-top-right-radius: $btn-icon-border-radius;
      border-bottom-right-radius: $btn-icon-border-radius;
    }

    &:not(.btn-check) + .btn {
      border-top-left-radius: $btn-icon-border-radius;
      border-bottom-left-radius: $btn-icon-border-radius;
    }
  }
}


// Button borderless
// ============================================================================

.btn-borderless {
  border-width: 0;

  &:focus {
    box-shadow: none;
  }
}


// Button variants
// ============================================================================

@each $color,
  $value in $theme-colors {
  .btn-#{$color} {
    @include generate-button-variant($value, $value);
  }
}

@each $color,
  $value in $theme-colors {
  .btn-outline-#{$color} {
    @include generate-button-outline-variant($value);
  }
}


// Button link
// ============================================================================

.btn-link {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  background-color: $white;
  gap: spacer(2);

  .icon {
    color: $primary;
  }

  &:hover {
    .icon {
      color: inherit;
    }
  }

  &:disabled,
  &.disabled {
    color: $gray-500;

    .icon {
      color: inherit;
    }
  }
}


// Button link variants
// ============================================================================

.btn-link-primary {
  color: $body-color;

  &:hover {
    color: $white;
    background-color: $red-wine-dark;
  }

  &:active,
  &.show {
    color: $primary;
    background-color: $primary-disabled;
  }
}

.btn-link-secondary {
  color: $gray-600;

  &:hover {
    color: $white;
    background-color: $gray-600-hover;
  }

  &:active,
  &.show {
    color: $white;
    background-color: $gray-600-focus;

    .icon {
      color: $white;
    }
  }
}
