/*
 * Content:
 *
 * Base
 * Theme variants generator
 */


// Base
// ============================================================================

.badge {
  border-style: solid;
  border-width: 1px;

  .icon {
    display: inline-flex;
    font-size: .625rem; // 10px
    color: inherit;
  }
}


// Theme variants generator
// ============================================================================

@each $color,
  $value in $theme-colors {
  .badge.bg-#{$color} {
    $badge-background-color: $value;
    $badge-border-color: $value;
    $badge-text-color: $badge-color;

    @if $color == "danger" {
      $badge-background-color: $red-100;
      $badge-border-color: $red-dark;
      $badge-text-color: $red-dark;
    }

    @else if $color == "success" {
      $badge-background-color: $green-100;
      $badge-border-color: $green-dark;
      $badge-text-color: $green-dark;
    }

    @else if $color == "warning" {
      $badge-background-color: $yellow-100;
      $badge-border-color: $yellow-700;
      $badge-text-color: $yellow-700;
    }

    @else if $color == "secondary" {
      $badge-background-color: $gray-100;
      $badge-border-color: $gray-700;
      $badge-text-color: $gray-700;
    }

    @include generate-badge-variant($badge-background-color, $badge-border-color, $badge-text-color);
  }
}
