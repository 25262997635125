/*
 * Content:
 *
 * Base
 */


// Base
// ============================================================================

.icon {
  display: flex;
  align-items: center;
  color: $body-color;
  text-decoration: none;
  background: none;
  border: none;

  @include media-breakpoint-up(md) {
    .material-icons-outlined {
      font-size: 1rem;
    }
  }
}
