/*
 * Content:
 *
 * Filter table
 * Filter header
 * Filter table title
 * Filter table form
 * Filter table date row
 * Filter table buttons row
 */


// Filter table
// ============================================================================

.filter-table-section {
  margin-top: $card-spacer-y;
  box-shadow: $filters-table-box-shadow;

  form {
    padding: 0 $card-spacer-x;

    @include media-breakpoint-up(md) {
      padding: 0 $card-desktop-spacer-x;
    }
  }

  @include media-breakpoint-up(md) {
    margin-top: $card-desktop-spacer-y;
  }
}


// Filter header
// ============================================================================

.filter-table-header {
  padding: $card-spacer-y $card-spacer-x 0;
  margin-bottom: spacer(2);

  @include media-breakpoint-up(md) {
    padding: $card-desktop-spacer-y $card-desktop-spacer-x 0;
  }
}


// Filter table title
// ============================================================================

.filter-table-title {
  @extend .h5;
  margin-bottom: 0;
  font-weight: $font-weight-bold;
}


// Filter table form
// ============================================================================

.filter-table-form {
  .form-group,
  fieldset {
    margin-bottom: spacer(2);

    &:nth-last-child(2) {
      margin-bottom: 0;
    }

    label,
    legend {
      margin-bottom: spacer(1);
    }
  }
}


// Filter table date row
// ============================================================================

.filter-table-date-row {
  @extend .gx-3, .row;
}


// Filter table buttons row
// ============================================================================

.filter-table-buttons-row {
  @extend .row;
  padding-top: spacer(5);
  padding-bottom: spacer(5);
}
