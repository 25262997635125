/*
 * Content:
 *
 * Base
 * Card title
 * Card header
 * Card body
 * Card hoverable
 */


// Card
// ============================================================================

.card {
  box-shadow: $card-box-shadow;
}


// Card title
// ============================================================================

.card-title {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend h5;
}


// Card header
// ============================================================================

.card-header {
  padding-bottom: 0;

  @include media-breakpoint-up(md) {
    padding: $card-cap-desktop-padding-top $card-cap-desktop-padding-x 0;
    border: none;
  }
}


// Card body
// ============================================================================

.card-body {
  dl {
    dd {
      word-break: break-word;
    }
  }

  @include media-breakpoint-up(md) {
    padding: $card-desktop-padding-top $card-desktop-spacer-x $card-desktop-spacer-y;
  }
}


// Card hoverable
// ============================================================================

.card-hoverable {
  &:hover {
    .card-hoverable-item {
      visibility: visible;
    }
  }

  .card-hoverable-item {
    visibility: hidden;
  }
}
