/*
 * Content:
 *
 * List group flush
 * List item data box
 */


// List group flush
// ============================================================================

.list-group-flush {
  & > .list-group-item {
    &:first-child {
      border-top-width: $list-group-border-width;
    }

    &:last-child {
      border-bottom-width: $list-group-border-width;
    }
  }
}


// List item data box
// ============================================================================

.list-item-data-box {
  display: flex;
  width: 100%;

  & > .list-item-data-box-big-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-start;
    word-break: break-all;
  }

  .list-item-data-box-title {
    font-weight: 700;
  }
}
